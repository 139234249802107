import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps
} from "@chakra-ui/react";

type ButtonProps = { to?: string } & ChakraButtonProps;

export const Button = ({
  children,
  width = "full",
  fontSize = "sm",
  variant = "solid",
  colorScheme = "primary",
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <ChakraButton
      width={width}
      data-cy="button"
      variant={variant}
      fontSize={fontSize}
      colorScheme={colorScheme}
      {...props}
    >
      {children}
    </ChakraButton>
  );
};
