import { Button, ButtonProps } from "@chakra-ui/react";

export const ButtonPrimary = ({
  children,
  colorScheme = "blue",
  w = "full",
  background = "#340072",
  color = "white",
  _hover = { background: "#111824" },
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <Button
      background={background}
      color={color}
      _hover={_hover}
      w={w}
      data-cy="primary-button"
      {...props}
    >
      {children}
    </Button>
  );
};
