export const convertTo12HourFormat = (time: string) => {
  const [hourStr, minuteStr] = time.split(":");
  const hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  if (isNaN(hour) || isNaN(minute)) {
    return null;
  }

  let convertedHour = hour;
  let format = "AM";
  let convertedMinute: any = minute;
  if (minute === 0) {
    convertedMinute = "00";
  }
  if (hour === 0) {
    convertedHour = 12;
  } else if (hour === 12) {
    format = "PM";
  } else if (hour > 12) {
    convertedHour = hour - 12;
    format = "PM";
  }

  return `${convertedHour}:${convertedMinute}${format}`;
};
