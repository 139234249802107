import { AxiosResponse } from "axios";
import instance from "data/axios-setup";
import { getCallSign } from "data/utils";
import { IPagination } from "data/models/base";
import { IClaimPerks, IGetPerksParams, IPerks } from "app/store/models/perks";

class PerksService {
  async getPerks(
    params: IGetPerksParams
  ): Promise<AxiosResponse<{ data: IPerks[]; pagination: IPagination }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.get(`/${callSign}/perks`, {
          params
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getSinglePerks(id: number): Promise<AxiosResponse<{ data: IPerks }>> {
    return new Promise(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const response = await instance.get(`/${callSign}/perks/${id}`);
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

  async unlockPerk(id: number) {
    return new Promise<AxiosResponse<{ data: any; message: string }>>(
      async (resolve, reject) => {
        try {
          const callSign = getCallSign();
          const res = await instance.post(`${callSign}/perks/unlock/${id}`);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  async ClaimPerk(id: number, body?: IClaimPerks) {
    return new Promise<
      AxiosResponse<{
        data: {
          discountCode?: string;
          redirectUrl?: string;
        };
        message: string;
      }>
    >(async (resolve, reject) => {
      try {
        const callSign = getCallSign();
        const res = await instance.post(`${callSign}/perks/claim/${id}`, body);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new PerksService();
