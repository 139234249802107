import { IClaimPerks, IGetPerksParams } from "../models/perks";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Dispatch } from "@reduxjs/toolkit";
import { actions } from "../reducers/perks.reducer";
import { DEFAULT_ERROR_MESSAGE, onResponseSuccess } from "data/error-mapping";
import perksService from "data/services/perks.service";

export const getPerks = (params: IGetPerksParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getAllPerksLoading());
      const res = await perksService.getPerks(params);
      onResponseSuccess(res, () => {
        dispatch(actions.getAllPerksSuccess(res.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.getAllPerksFailed(msg));
    }
  };
};

export const getSinglePerks = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.getSinglePerksLoading());
      const res = await perksService.getSinglePerks(id);
      onResponseSuccess(res, () => {
        dispatch(actions.getSinglePerksSuccess(res.data));
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      if (!msg) return;
      toast.error(msg);
      dispatch(actions.getSinglePerksFailed(msg));
    }
  };
};

export const unlockPerk = (id: number, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.performingAction());
      const res = await perksService.unlockPerk(id);

      onResponseSuccess(res, () => {
        dispatch(actions.performingActionSuccess(res.data.data));
        toast.success(res.data.message);
        !!cb && cb();
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.performingActionError());
    }
  };
};

export const claimPerk = (id: number, body?: IClaimPerks, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(actions.performingAction());
      const res = await perksService.ClaimPerk(id, body);

      onResponseSuccess(res, () => {
        dispatch(actions.performingActionSuccess(res.data.data));
        toast.success(res.data.message);
        !!cb && cb();
      });
    } catch (err: any) {
      const error = err as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      toast.error(msg);
      dispatch(actions.performingActionError());
    }
  };
};
