import { useDisclosure, useToast } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";
import { getAllFiatWallets } from "app/store";
import { configs } from "data/config";
import { DEFAULT_ERROR_MESSAGE } from "data/error-mapping";
import walletsService from "data/services/wallets.service";
import { useCallback, useEffect, useRef, useState } from "react";
import IframeComponent from "./iframe";
import {
  useGetAllTransactions,
  useGetRecentTransactions
} from "./use-transaction-functions";
import { useAppDispatch } from "./useReduxState";

export const useLucrestackCheckout = () => {
  const chakraToast = useToast();

  const { fetchRecentTransactions } = useGetRecentTransactions();
  const { fetchAllTransactions } = useGetAllTransactions();
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen: OpenCheckout } = useDisclosure();

  const iframeRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    const iframe = iframeRef.current;

    const handleIframeLoad = () => {
      if (iframe?.contentWindow?.document?.body) {
        iframe.contentWindow.document.body.style.overflow = "hidden";
      }
    };

    // Add load event listener to the iframe
    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    // Cleanup listener when component unmounts
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, []);

  const messageOrigin = configs.LUCRESTACK_CHECKOUT_URL || "";

  const [refCode, setRefCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const initiateCheckout = async (amount: number, currency: string) => {
    setLoading(true);
    try {
      const { data } = await walletsService.initiateCheckout({
        amount,
        currency
      });

      setRefCode(data.reference_code);
      OpenCheckout();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const finalizeCheckout = async (
    order_reference_code: string,
    currency: string
  ) => {
    setLoading(true);
    onClose();
    try {
      await walletsService.finalizeCheckout({
        referenceCode: order_reference_code,
        currency
      });

      dispatch(getAllFiatWallets());
      fetchAllTransactions();
      fetchRecentTransactions();
      setLoading(false);
      dispatch(getAllFiatWallets());
      window.location.reload();
    } catch (error: any) {
      const msg = error.response?.data.message || DEFAULT_ERROR_MESSAGE;
      chakraToast({
        title: "Error Funding Wallet",
        description: msg,
        status: "error",
        variant: "subtle",
        position: "top-right",
        duration: 9000,
        isClosable: true
      });
      setLoading(false);
    }
  };

  const CheckoutModal = useCallback(
    () =>
      !refCode ? null : (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={"full"}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              {/* <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000
            }}
          > */}
              <IframeComponent
                iframeRef={iframeRef}
                messageOrigin={messageOrigin}
                refCode={refCode}
              />
              {/* <iframe
                ref={iframeRef}
                style={{
                  border: "none",
                  background: "transparent",
                  overflow: "hidden",
                  width: "100%",
                  height: "calc(100vh - 1em)"
                }}
                title="MPWCheckout"
                src={`${messageOrigin}/popup/${refCode}`}
                //@ts-ignore
                frameborder="0"
              ></iframe> */}
              {/* </div> */}
            </ModalBody>
          </ModalContent>
        </Modal>
      ),
    [refCode, isOpen]
  );

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.origin !== messageOrigin) {
      console.log("Message received from Untrusted Origin:", event.origin);
      return;
    }

    const message = event.data;
    // console.log("Message received", message);
    if (message.type === "closeMPWCheckoutModal") {
      onClose();
    } else if (message.type === "MPWCheckoutSuccess") {
      finalizeCheckout(
        message?.data?.data?.order_reference_code as string,
        message?.data?.data?.currency as string
      );
    } else if (message.type === "MPWCheckoutFailed") {
      onClose();
      chakraToast({
        title: "Transaction Failed",
        description: "Error Funding Wallet",
        status: "error",
        variant: "subtle",
        position: "top-right",
        duration: 9000,
        isClosable: true
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [handleMessage]);

  return {
    loading,
    initiateCheckout,
    CheckoutModal
  };
};
