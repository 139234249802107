import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import AuthLayout from "views/layout/auth-layout";
import Login from "views/pages/authentication/login";
import Forbidden from "views/pages/unauthorized/forbidden";
import TeamInviteVerification from "views/pages/team-invite";
import TwoFALogin from "views/pages/authentication/login/two-fa";
import OpenSecuredLayout from "views/layout/open-layout";
import PreviewEvent from "views/containers/events/preview-events";

const PerksPage = lazy(() => import("views/pages/perks"));
const DashboardLayout = lazy(() => import("views/layout/dashboard-layout"));
const ForgetPassword = lazy(
  () => import("views/pages/authentication/forget-password")
);
const ResetPassword = lazy(
  () => import("views/pages/authentication/reset-password")
);
const Register = lazy(() => import("views/pages/authentication/register"));
const SetupLayout = lazy(() => import("views/layout/setupLayout"));

const BusinessRegistration = lazy(
  () => import("views/pages/business-registration")
);
const ProfileRootPage = lazy(
  () => import("views/pages/profile/pages/overview")
);
const ProfileLegalPage = lazy(() => import("views/pages/profile/pages/legal"));
const ProfileReportsPage = lazy(
  () => import("views/pages/profile/pages/reports")
);
const ProfileSupportPage = lazy(
  () => import("views/pages/profile/pages/support")
);
const ProfileSecurityPage = lazy(
  () => import("views/pages/profile/pages/security")
);
const ProfileNotificationsPage = lazy(
  () => import("views/pages/profile/pages/notifications")
);
const ProfileBanksAndWalletPage = lazy(
  () => import("views/pages/profile/pages/banks-wallet")
);
const Verification = lazy(() => import("views/pages/authentication/verify"));
const TicketVerification = lazy(
  () => import("views/pages/ticket-verification")
);
const NotFound = lazy(() => import("views/components/not-found"));
const TeamManagement = lazy(() => import("views/pages/team-management"));
const SetPin = lazy(() => import("views/pages/authentication/set-pin"));
const ChooseBusiness = lazy(
  () => import("views/pages/authentication/choose-business")
);
const SecureRoute = lazy(() => import("./secureRoute"));
const GuardRoute = lazy(() => import("./guardRoute"));
const Recipients = lazy(() => import("views/pages/recipients"));
const OnboardRoute = lazy(() => import("./onboardRoute"));
const SubBusinessesPage = lazy(() => import("views/pages/sub-businesses"));
const SubBusinessesAddPage = lazy(
  () => import("views/pages/sub-businesses/add")
);
const SubBusinessesSinglePage = lazy(
  () => import("views/pages/sub-businesses/single-business")
);
const ModifySubBuinessesPage = lazy(
  () => import("views/pages/sub-businesses/modify-business")
);
const CreateEventsPage = lazy(() => import("views/pages/events/create-event"));
const ModifyEventsPage = lazy(() => import("views/pages/events/modify-event"));
const ManageEventsPage = lazy(() => import("views/pages/events/manage-event"));
const NotificationsPage = lazy(() => import("views/pages/notifications"));
const Profile = lazy(() => import("views/pages/profile"));
const Events = lazy(() => import("views/pages/events"));
const Dashboard = lazy(() => import("views/pages/dashboard"));
const Wallet = lazy(() => import("views/pages/wallet"));
const Transactions = lazy(() => import("views/pages/transactions"));
const VirtualAccounts = lazy(() => import("views/pages/virtual-accounts"));
const Cards = lazy(() => import("views/pages/cards"));
const PrivacyPolicy = lazy(() => import("views/pages/privacy"));
const ManageSubscription = lazy(
  () => import("views/pages/profile/pages/subscription")
);
const ManageSubscriptionMethod = lazy(
  () => import("views/pages/profile/pages/subscription/method")
);
const ChoosePlan = lazy(
  () =>
    import(
      "views/containers/business-registration/subscription/pages/choose-plan"
    )
);

const CompareSubscription = lazy(
  () => import("views/pages/profile/pages/subscription/compare")
);
const UpgradeSubscription = lazy(
  () => import("views/pages/profile/pages/subscription/upgrade")
);
const DowngradeSubscription = lazy(
  () => import("views/pages/profile/pages/subscription/downgrade")
);

const FX = lazy(() => import("views/pages/fx"));
const LienPage = lazy(() => import("views/pages/liens"));
const Bids = lazy(() => import("views/pages/bids"));

const routes: RouteObject[] = [
  {
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        element: <Dashboard />,
        path: "/"
      },
      {
        element: <Transactions />,
        path: "/transactions"
      },
      {
        element: <NotificationsPage />,
        path: "/notifications"
      },
      {
        element: <Wallet />,
        path: "wallets"
      },
      {
        element: <FX />,
        path: "fx"
      },
      {
        element: <SubBusinessesPage />,
        path: "sub-businesses"
      },
      {
        element: <SubBusinessesAddPage />,
        path: "/sub-businesses/add-business"
      },
      {
        path: "/sub-businesses/modify/:subBusinessCallSign",
        element: <ModifySubBuinessesPage />
      },
      {
        element: <SubBusinessesSinglePage />,
        path: "/sub-businesses/:subBusinessCallSign"
      },
      {
        path: "/profile",
        element: <Profile />,
        children: [
          { index: true, element: <ProfileRootPage /> },
          { path: "legal", element: <ProfileLegalPage /> },
          { path: "reports", element: <ProfileReportsPage /> },
          { path: "support", element: <ProfileSupportPage /> },
          { path: "security", element: <ProfileSecurityPage /> },
          { path: "subscription", element: <ManageSubscription /> },
          { path: "banks-wallet", element: <ProfileBanksAndWalletPage /> },
          { path: "notifications", element: <ProfileNotificationsPage /> },
          {
            path: "subscription/method",
            element: <ManageSubscriptionMethod />
          }
        ]
      },
      {
        path: "/events",
        element: <Events />
      },
      {
        path: "/events/modify/:id",
        element: <ModifyEventsPage />
      },
      { path: "/events/create", element: <CreateEventsPage /> },
      {
        path: "/events/manage/:id",
        element: <ManageEventsPage />
      },
      {
        path: "/team",
        element: <TeamManagement />
      },
      {
        path: "virtual-accounts",
        element: <VirtualAccounts />
      },
      { element: <Cards />, path: "/virtual-cards" },
      { element: <LienPage />, path: "/lien" },
      { element: <Recipients />, path: "/recipients" },
      { element: <PerksPage />, path: "perks" },
      { element: <Forbidden />, path: "/forbidden" },
      { element: <Bids />, path: "/bids" }
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        element: <Login />,
        path: "/auth/login"
      },
      {
        element: <TwoFALogin />,
        path: "/auth/login/2fa"
      },
      {
        element: <Register />,
        path: "/auth/register"
      },
      {
        element: <ForgetPassword />,
        path: "/auth/forgot-password"
      },
      {
        element: <ResetPassword />,
        path: "/auth/reset-password/:email/:token"
      },
      {
        element: <TicketVerification />,
        path: "/verify-ticket"
      },
      {
        element: <TeamInviteVerification />,
        path: "/invite-member"
      }
    ]
  },
  {
    element: (
      <SecureRoute>
        <AuthLayout />
      </SecureRoute>
    ),
    children: [
      {
        element: <Verification />,
        path: "/auth/verify"
      },
      {
        element: <SetPin />,
        path: "/auth/set-pin"
      }
    ]
  },
  {
    element: (
      <GuardRoute>
        <AuthLayout />
      </GuardRoute>
    ),
    children: [
      {
        element: <ChooseBusiness />,
        path: "/auth/choose-business"
      }
    ]
  },
  {
    element: (
      <OnboardRoute>
        <SetupLayout />
      </OnboardRoute>
    ),
    children: [
      {
        element: <BusinessRegistration />,
        path: "/onboard/business"
      },
      {
        element: <ChoosePlan />,
        path: "/onboard/choose-plan"
      }
    ]
  },
  {
    element: (
      <PrivateRoute>
        <OpenSecuredLayout />
      </PrivateRoute>
    ),
    children: [
      {
        element: <CompareSubscription />,
        path: "/profile/subscription/compare"
      },
      {
        element: <UpgradeSubscription />,
        path: "/profile/subscription/upgrade"
      },
      {
        element: <DowngradeSubscription />,
        path: "/profile/subscription/downgrade"
      }
    ]
  },
  { element: <PrivacyPolicy />, path: "/legal/terms-and-conditions" },
  { element: <PreviewEvent />, path: "/event/preview/:id" },
  {
    element: <NotFound />,
    path: "*"
  }
];

export default routes;
