import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "data/models/base";
import { IPerks, PerksState } from "../models/perks";

const pagination = {
  next: 0,
  total: 0,
  prevPage: 0,
  lastPage: 0,
  pageSize: 10,
  hasNext: false,
  currentPage: 1,
  hasPrevious: false
};

const initialState: PerksState = {
  allPerks: {
    data: [],
    error: "",
    loading: false,
    pagination: pagination
  },
  singlePerk: {
    data: null,
    error: "",
    loading: false
  },
  perkActions: {
    loading: false,
    data: {}
  }
};

export const PerksReducer = createSlice({
  initialState,
  name: "perks",
  reducers: {
    getAllPerksLoading: (state) => {
      state.allPerks.loading = true;
    },
    getAllPerksSuccess: (
      state,
      action: PayloadAction<{
        data: IPerks[];
        pagination: IPagination;
      }>
    ) => {
      state.allPerks.loading = false;
      state.allPerks.data = action.payload.data;
      state.allPerks.pagination = action.payload.pagination;
    },
    getAllPerksFailed: (state, action: PayloadAction<string>) => {
      state.allPerks.loading = false;
      state.allPerks.error = action.payload;
    },

    getSinglePerksLoading: (state) => {
      state.singlePerk.loading = true;
    },
    getSinglePerksSuccess: (
      state,
      action: PayloadAction<{
        data: IPerks;
      }>
    ) => {
      state.singlePerk.loading = false;
      state.singlePerk.data = action.payload.data;
    },

    getSinglePerksFailed: (state, action: PayloadAction<string>) => {
      state.singlePerk.loading = false;
      state.singlePerk.error = action.payload;
    },

    performingAction: (state) => {
      state.perkActions.loading = true;
      state.perkActions.data = null;
    },
    performingActionSuccess: (state, action) => {
      state.perkActions.loading = false;
      state.perkActions.data = action.payload;
    },
    performingActionError: (state) => {
      state.perkActions.loading = false;
    }
  }
});

export const actions = PerksReducer.actions;
export default PerksReducer.reducer;
