import {
  Badge,
  Box,
  Heading,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text
} from "@chakra-ui/react";
import { UilTimes } from "@iconscout/react-unicons";
import { useAppSelector } from "app/hooks";
import { convertTo12HourFormat } from "app/hooks/use-convert-time";
import { Event } from "app/store/models/events";
import getSymbolFromCurrency from "currency-symbol-map";
import { ArrowLeft, ArrowRight, Calendar, Global, Ticket } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { Button } from "views/components/button";
// import {
//   FacebookIcon,
//   LinkedinIcon,
//   TwitterIcon
// } from "views/components/icons/events-icons";
import NotFound from "views/components/not-found";

const PreviewEvent = () => {
  const [event] = useAppSelector((state) => [state.events.event]);
  const { id } = useParams<{ id: string }>();

  const getFreeValue = (event: Event) => {
    const newStateCode = event.ticket.find((data: any) => {
      return data.type === "free";
    });
    if (newStateCode) return "free";
  };

  function findMinimumTicket(event: Event) {
    let minPriceTicket = event.ticket[0];
    for (let i = 1; i < event.ticket.length; i++) {
      if (+event.ticket[i].price < +minPriceTicket.price) {
        minPriceTicket = event.ticket[i];
      }
    }
    return minPriceTicket;
  }

  const tagColors: any = useMemo(
    () => ({
      0: "cyan",
      1: "purple",
      2: "red",
      3: "orange",
      4: "yellow",
      5: "green",
      6: "teal",
      7: "blue",
      8: "cyan",
      9: "purple",
      10: "pink"
    }),
    []
  );
  const getTagColor = (idx: number) =>
    tagColors[idx % Object.keys(tagColors).length];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [images, setImages] = useState<string[] | null>(null);
  const openImage = (idx: string) => {
    if (!event) return;
    const allImages: string[] = [
      event.optionalImage[idx],
      ...event.optionalImage
    ];
    setImages(allImages);
  };

  const closeImage = () => {
    setImages(null);
  };

  return (
    <div>
      {!event ? (
        <>
          <NotFound />
        </>
      ) : (
        <div className="max-w-7xl mx-auto bg-white p-6 py-12">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold ">Event Preview</h2>

            <Link to={`/events/modify/${id}`}>
              <UilTimes />
            </Link>
          </div>

          <Stack
            direction={["row"]}
            gap={["6", "10", "12", "16"]}
            flexDirection={["column", "column", "row", "row"]}
            display="flex"
            position={"relative"}
          >
            <Box flexBasis={["45%"]} className="sticky">
              <div>
                {" "}
                <Box color="white" position={"relative"} rounded={"12px"}>
                  {" "}
                  {/* <div className="absolute top-0 left-0">
                            <Flex
                              className="hover:scale-120 transition-all duration-300"
                              boxShadow={"0px 2px 24px rgba(9, 44, 42, 0.1)"}
                              _hover={{
                                boxShadow: "0px 8px 30px rgba(9, 44, 42, 0.1)",
                                boxSize: "12",
                              }}
                              border={"1px"}
                              p="1"
                              rounded={"full"}
                              boxSize="10"
                              color="gray.800"
                              alignItems={"center"}
                              justifyContent="center"
                              background="rgba(255, 255, 255, 0.81)"
                              borderColor="rgba(255, 255, 255, 0.9)"
                              cursor="pointer"
                              onClick={() =>
                                navigate({
                                  to: "/events",
                                  search: { type: "" },
                                })
                              }
                            >
                              <Icon
                                as={IoMdArrowRoundBack}
                                fontWeight={800}
                                fontSize={18}
                              />
                            </Flex>
                          </div> */}
                  <Image
                    src={event?.ticketImage} // Replace with your image source
                    alt="Event Image"
                    objectFit="cover"
                    width="100%"
                    height="500px"
                    className="rounded-[12px]"
                  />
                  <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    bgGradient="linear(to-t, blackAlpha.900, blackAlpha.900, blackAlpha.700, transparent)"
                    p="6"
                    pt={"32"}
                    roundedBottom={"12px"}
                  >
                    <Heading
                      as="h1"
                      fontSize="32px"
                      fontWeight={"700"}
                      textTransform="capitalize"
                      className="text-white"
                      mb={3}
                    >
                      {event?.name}
                    </Heading>
                    <Text fontSize="18px" color="white">
                      {new Date(event.date).toLocaleDateString("en-us", {
                        day: "numeric",
                        month: "short",
                        year: "numeric"
                      })}
                      {event.isMultipleDayEvent && event?.eventDates && (
                        <>
                          {" "}
                          -{" "}
                          {new Date(
                            event?.eventDates[event?.eventDates.length - 1].date
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                          })}
                        </>
                      )}
                    </Text>

                    {/* <div className="flex space-x-2 mt-2 text-white">
                      <LinkedinIcon />
                      <TwitterIcon />
                      <FacebookIcon />
                    </div> */}
                  </Box>
                </Box>
                <div className="flex w-full items-center gap-3 mt-8">
                  <Button
                    size={"lg"}
                    rounded={"full"}
                    px={8}
                    color="white"
                    className="items-center w-full justify-center font-semibold  !bg-primary-500 rounded-full px-5 !hover:bg-secondary-600 transition duration-300"
                  >
                    Register for event
                  </Button>
                  <Button
                    size={"lg"}
                    rounded={"full"}
                    variant="outline"
                    border="solid 1px #340072"
                    px={8}
                    w="full"
                  >
                    Share
                  </Button>
                </div>
              </div>
            </Box>

            <Box
              flexBasis={["55%"]}
              height={["full", "full", "80vh", "80vh"]}
              overflowY="auto"
              className="no-scrollbar"
              pb={14}
            >
              <div>
                <p className="text-[#212327] text-xl font-bold mb-3">
                  Event Details
                </p>

                {!event.isMultipleDayEvent ? (
                  <div className="flex lg:flex-row items-start lg:items-center flex-col gap-3">
                    <div className="rounded-xl p-2 bg-[#F1F5F9]">
                      <Calendar variant="Bold" className="text-primary-500 " />
                    </div>
                    <p className="text-[#475569] text-base">
                      {new Date(event.date).toLocaleDateString("en-us", {
                        day: "numeric",
                        month: "short",
                        year: "numeric"
                      })}
                      {event.isMultipleDayEvent && event?.eventDates && (
                        <>
                          {" "}
                          -{" "}
                          {new Date(
                            event?.eventDates[event?.eventDates.length - 1].date
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric"
                          })}
                        </>
                      )}
                      {!event.isMultipleDayEvent && (
                        <span>
                          , {convertTo12HourFormat(event.time)} {event.timezone}
                        </span>
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    {" "}
                    {event.isMultipleDayEvent && event?.eventDates && (
                      <div className="mt-3">
                        {event?.eventDates?.map((singleDate: any, idx: any) => (
                          <div
                            className="flex items-center gap-4 mt-1"
                            key={idx}
                          >
                            <div className="flex items-center gap-3">
                              <div className="rounded-xl p-2 bg-[#F1F5F9]">
                                <Calendar
                                  variant="Bold"
                                  className="text-primary-500 "
                                />
                              </div>
                              <p className="text-[#475569] text-base">
                                {" "}
                                {new Date(singleDate.date).toLocaleDateString(
                                  "en-us",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric"
                                  }
                                )}
                                <span className="text-[#475569] text-base">
                                  , {convertTo12HourFormat(singleDate.time)}{" "}
                                  {event.timezone}
                                </span>
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 my-4">
                <div>
                  {event?.attendanceType === "physical" ? (
                    <div className="flex lg:flex-row items-center gap-3">
                      <div className="rounded-xl p-2 bg-[#F1F5F9]">
                        <Global variant="Bold" className="text-primary-500 " />
                      </div>
                      {event.displayLocationToPublic ? (
                        <div>
                          {" "}
                          <p className="text-[#475569] text-base">
                            <a
                              href={`https://www.google.com/maps/dir/current+location/${event?.address}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {event?.address}, {event?.addressLine},{" "}
                              {event?.state}
                            </a>
                          </p>
                        </div>
                      ) : (
                        <span>Address available on ticket purchase</span>
                      )}
                    </div>
                  ) : event?.attendanceType === "physical-and-virtual" ? (
                    <div className="flex lg:flex-row items-start flex-col gap-3">
                      <div className="rounded-xl p-2 bg-[#F1F5F9]">
                        <Global variant="Bold" className="text-primary-500 " />
                      </div>
                      <div>
                        {" "}
                        {event.displayLocationToPublic ? (
                          <div className="text-[#475569] text-base">
                            {" "}
                            <p>
                              <a
                                href={`https://www.google.com/maps/dir/current+location/${event?.address}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {event?.address}, {event?.addressLine},{" "}
                                {event?.state}
                              </a>
                            </p>
                          </div>
                        ) : (
                          <p className="text-[#475569]">
                            Address available on ticket purchase
                          </p>
                        )}
                        {event?.displayVirtualUrlToPublic ? (
                          <div className="mt-3 text-[#475569]">
                            <p className=" text-xl font-bold">Virtual Url:</p>

                            {event.virtualUrl.map((link, idx) => (
                              <p key={idx}>{link}</p>
                            ))}
                          </div>
                        ) : (
                          <p className="text-[#475569]">
                            Link to access events will be sent to attendees
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex lg:flex-row items-center gap-3">
                      <div className="rounded-xl p-2 bg-[#F1F5F9]">
                        <Global variant="Bold" className="text-primary-500 " />
                      </div>
                      {event?.displayVirtualUrlToPublic ? (
                        <div className="mt-3">
                          <p className="text-[#212327] text-lg font-semibold mt-3 mb-1">
                            Virtual Url:
                          </p>

                          {event.virtualUrl.map((link, idx) => (
                            <p className="text-[#475569]" key={idx}>
                              {link}
                            </p>
                          ))}
                        </div>
                      ) : (
                        <span className="text-[#475569]">
                          Link to access events will be sent to attendees
                        </span>
                      )}{" "}
                    </div>
                  )}{" "}
                </div>

                <div className="flex lg:flex-row items-center gap-3">
                  <div className="rounded-xl p-2 bg-[#F1F5F9]">
                    <Ticket variant="Bold" className="text-primary-500 " />
                  </div>
                  <p className="text-[#475569] text-base capitalize">
                    <span>{event?.ticket?.length > 1 && <>From </>}</span>
                    {getFreeValue(event)
                      ? getFreeValue(event)
                      : `${getSymbolFromCurrency(
                          findMinimumTicket(event)?.currency ?? "ngn"
                        )}${parseFloat(
                          findMinimumTicket(event).price as unknown as string
                        ).toLocaleString()}`}
                  </p>
                </div>
              </div>

              <div>
                <p className="text-[#212327] text-xl font-bold mb-3">
                  About this event
                </p>
                <p className="text-[#475569] text-base">{event.description}</p>
              </div>
              <div>
                <p className="text-[#212327] text-xl font-bold mt-3 mb-1">
                  Organizer Name
                </p>
                <p className="text-[#475569] text-base capitalize">
                  {event.organizersName}
                </p>
              </div>
              <div>
                <p className="text-[#212327] text-xl font-bold mt-3 mb-1">
                  Category
                </p>
                <p className="text-[#475569] text-base capitalize">
                  {event.category}
                </p>
              </div>
              {event.tags && (
                <div>
                  <p className="text-[#212327] text-xl font-bold mt-3 mb-2">
                    Tags
                  </p>
                  <Stack direction="row" py="2" gap="2" flexWrap={"wrap"}>
                    {event?.tags ? (
                      event?.tags
                        .trim()
                        .split(",")
                        .map((el, idx) => (
                          <Badge
                            mx="0 !important"
                            colorScheme={getTagColor(idx)}
                            key={idx}
                            py="1"
                            px="3"
                            borderRadius={"full"}
                          >
                            {el}
                          </Badge>
                        ))
                    ) : (
                      <Box>--</Box>
                    )}
                  </Stack>
                </div>
              )}
              {event.eventTermsConditions && (
                <div>
                  <p className="text-[#212327] text-xl font-bold mt-3 mb-2">
                    Terms and condition
                  </p>
                  <p className="text-[#475569] text-base">
                    {event.eventTermsConditions}
                  </p>
                </div>
              )}
              {event.optionalImage.length > 0 && (
                <Box>
                  <p className="text-[#212327] text-xl font-bold mt-3">
                    Additional Images
                  </p>
                  <Stack direction="row" gap={"2"} overflowX="auto" mt="4">
                    {event?.optionalImage.map((image: string, idx: string) => (
                      <Image
                        onClick={() => openImage(idx)}
                        cursor="pointer"
                        src={image}
                        borderRadius="md"
                        key={idx}
                        height="120px"
                        width={"auto"}
                        alt="event image"
                      />
                    ))}
                  </Stack>
                </Box>
              )}
            </Box>
          </Stack>
        </div>
      )}

      <Modal isOpen={!!images} onClose={closeImage} size="5xl" isCentered>
        <ModalOverlay backdropFilter="blur(4px) sepia(30%)" />
        <ModalContent bg="transparent" borderWidth={"0"} position="relative">
          <Slide {...properties}>
            {images?.map((el, idx) => (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                key={idx}
              >
                <Image
                  src={el}
                  width="auto"
                  objectFit="cover"
                  borderRadius={"lg"}
                  height="70vh"
                  key={idx}
                  alt="images"
                />
              </Box>
            ))}
          </Slide>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default PreviewEvent;

const properties = {
  prevArrow: (
    <div>
      <ArrowLeft
        variant="Bold"
        size="40"
        color="#ffffff"
        className="xl:translate-x-[calc(-100%-5px)]"
      />
    </div>
  ),
  nextArrow: (
    <div>
      <ArrowRight
        size="40"
        variant="Bold"
        color="#ffffff"
        className="xl:translate-x-[calc(100%+5px)]"
      />
    </div>
  )
};
